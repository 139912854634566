import { useSearchParams } from 'react-router-dom';
import { Container, ContentLabel, SectionLabel } from './styles';

const DataDeletionRequest = () => {
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source');

    const isFromApp = source === 'webview';

    return (
        <Container isFromApp={isFromApp}>
            <SectionLabel>Data Deletion Request</SectionLabel>
            <ContentLabel>
                At Lucky Lotto, we respect your right to control your personal data. If you wish to delete your user data from our systems, please follow the steps below:
            </ContentLabel>

            <SectionLabel>How to Request Data Deletion?</SectionLabel>
            <ContentLabel>
                <br />
                <b>Send an Email: </b>
                <br />
                <br />
                1. Send an email to aisteelia369@gmail.com with the subject line <b>Data Deletion Request</b>
                <br />
                <br />
                2. In the email, please include the following details:
                <br />
                - Your full name
                <br />
                - The email address associated with your Lucky Lotto account
                <br />
                - A clear and explicit request to delete your user data
                <br />
                <br />
                3. Verification: To ensure the request is legitimate, we may require you to verify your identity. We will provide you with instructions on how to complete this step.

                <br />
                <br />
                <br />
                <b>Delete account in Lucky Lotto application:</b>
                <br />
                <br />
                1. Open the Lucky Lotto app on your device
                <br />
                2. Login to your account
                <br />
                3. Go to the Edit Profile section in Profile tab
                <br />
                4. Scroll down to the bottom of the page and tap on the Delete Account button
            </ContentLabel>

            <SectionLabel>Data Deletion Process</SectionLabel>
            <ContentLabel>
                - Once we receive your request, we will begin the process of deleting your user data from our systems.
                <br />
                - This process may take a short time to complete, and you will receive a notification when your data has been successfully deleted.
                <br />
                - Within 7 days of your deletion request, you can still log in to your account and the deletion will automatically cancel when you log in again.
                <br />
                - After 7 days from making the deletion request, all data related to your account will be permanently deleted from the Lucky Lotto system.
            </ContentLabel>

            <SectionLabel>Important Notes</SectionLabel>
            <ContentLabel>
                - Once your data has been deleted, we cannot restore it. This means you will lose all information, data and settings related to your account.
                <br />
                - If you want to use our services after deleting your account, you will need to create a new account and start from scratch.
            </ContentLabel>
        </Container>
    );
}

export default DataDeletionRequest;
