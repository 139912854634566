import { useSearchParams } from 'react-router-dom';
import { Container, ContentLabel, SectionLabel } from './styles';

const Privacy = () => {
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source');

    const isFromApp = source === 'webview';

    return (
        <Container isFromApp={isFromApp}>
            <SectionLabel>Privacy policy</SectionLabel>
            <ContentLabel>
                Welcome to Lucky Lotto - the app that allows you to see live 4D results and comprehensive lottery statistics for free.
                <br />
                <br />
                Although our application allows you to subscription to remove all ads and improve your experience of using the application. But you can still continue to watch ads and use all the features completely free of charge.
                <br />
                <br />
                We are committed to protecting and respecting your privacy.
                <br />
                <br />
                This policy explains the use of certain personal information that we may have collected from you, or that you have provided to us during of using this application.
            </ContentLabel>

            <SectionLabel>What information do we collect about you?</SectionLabel>
            <ContentLabel>
                We collect information about your device when you use our app. There will also be some personal information that you will actively provide to us when you contact us from within this app.
            </ContentLabel>

            <SectionLabel>How do we use your information?</SectionLabel>
            <ContentLabel>
                Your information is used by us for authentication during the use of this application. It also helps us get statistical data to improve and develop future content.
            </ContentLabel>

            <SectionLabel>Who do we share your information with?</SectionLabel>
            <ContentLabel>
                Your personal information is stored and kept completely confidential by us. In addition, some information that identifies your device is automatically collected by third-party services that we integrate into this app.
                <br />
                <br />
                Third party services like Google Admob, Google Pay, Apple Pay, Google Analytics collect your information for purchase, ad delivery and data analytics.
            </ContentLabel>

            <SectionLabel>Children's privacy?</SectionLabel>
            <ContentLabel>
                Lucky Lotto is not intended for children under the age of 13. In certain cases this age may be higher due to local regulatory requirements, please see your local privacy policy for more information.
                <br />
                <br />
                If you believe that we have personal data about or have collected from children under the relevant age, please contact us. We will delete the information related to that child.
            </ContentLabel>
        </Container>
    );
}

export default Privacy;
