import { useSearchParams } from 'react-router-dom';
import { Container, ContentLabel, SectionLabel } from './styles';

const Terms = () => {
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source');

    const isFromApp = source === 'webview';

    return (
        <Container isFromApp={isFromApp}>
            <SectionLabel>Terms of Use</SectionLabel>
            <ContentLabel>
                Welcome to Lucky Lotto - the app that allows you to see live 4D results and comprehensive lottery statistics for free.
                <br />
                <br />
                Although our application allows you to subscription to remove all ads and improve your experience of using the application. But you can still continue to watch ads and use all the features completely free of charge.
                <br />
                <br />
                This is an agreement between you and Lucky Lotto ("Lucky Lotto", "we" or "our") on digital service implemented by Lucky Lotto ("Service"). The Lucky Lotto Terms of Use, the rules, policies and guidelines we provide that related with the Service and following terms constitute one part of this agreement and affecting your use of the Service.
            </ContentLabel>

            <SectionLabel>Your account - Notification options</SectionLabel>
            <ContentLabel>
                You do not need to create an account to use our services. To manage Lucky Lotto's notification, you can switch on/off from Application Configuration in Lucky Lotto app.
            </ContentLabel>

            <SectionLabel>Premium membership package</SectionLabel>
            <ContentLabel>
                As part of our Services, we may offer and modify the Membership plan and change its features from time to time. By subcription for a Membership plan, you agree to the terms, conditions of use and limitations of these plans, which are posted on our website or app.
            </ContentLabel>

            <SectionLabel>Premium package information</SectionLabel>
            <ContentLabel>
                Depending on their needs and capabilities, Users can continue to use the application for free or choose to use the packages below. Each wedding package is limited to 1 user only. Lucky Lotto reserves the right to take necessary technical measures if any fraud is detected.
                <br />
                <br />
                - Premium 1 month
                <br />
                - Premium 3 months
                <br />
                - Premium 6 months
                <br />
                <br />
                <br />
                <br />
                Subscription packages are intended for:
                <br />
                <br />
                - Remove all ads during the time you use the app
                <br />
                - Use all statistics without watching ads
                <br />
                - Improve your experience using the app
                <br />
                <br />
                <br />
                <br />
                Subscription period and price will vary from plan to plan.
            </ContentLabel>

            <SectionLabel>Premium membership package</SectionLabel>
            <ContentLabel>
                As part of our Services, we may offer and modify the Membership plan and change its features from time to time. By subcription for a Membership plan, you agree to the terms, conditions of use and limitations of these plans, which are posted on our website or app.
            </ContentLabel>

            <SectionLabel>Fees and Renewals</SectionLabel>
            <ContentLabel>
                Fees for purchased content, Memberships, subscriptions and other services will be stated at the time of your purchase or subscription, if applicable, and are made public on our sites. Fees for Membership packages are subject to change. Fees may be taxable, collected by us or a third party and are non-refundable except as specifically described in these Terms.
                <br />
                <br />
                Your membership plan will be automatically renewed at the end of the subscription period through intermediaries such as Google Pay and Apple Pay. We do not hold any of your payment information. You can cancel the auto-renewal at any time and continue to use your current membership until the end of your subscription period.
                <br />
                <br />
                If all credit cards or payment methods you have ever made on our system are declined, we may cancel your Membership, unless you provide us with a credit card. new or new payment method. If you provide a new credit card or payment method and make a successful transaction before the Membership expires, the duration of the new plan will be based on the original renewal date, not the successful transaction date.
            </ContentLabel>

            <SectionLabel>User rights</SectionLabel>
            <ContentLabel>
                - To use Lucky Lotto's services according to the subscription package
                <br />
                - Free to use and share information related or posted inside Lucky Lotto
                <br />
                - Get notified as soon as a new feature is available
            </ContentLabel>

            <SectionLabel>Disputes</SectionLabel>
            <ContentLabel>
                Any dispute or claim arising out of or relating to these Terms, or your use of the Service is subject to dispute resolution, the law, disclaimer and all other terms otherwise in the Lucky Lotto Terms of Use.
            </ContentLabel>
        </Container>
    );
}

export default Terms;
